/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */
/* Swiper */
@import '~swiper/css/bundle';

/* FullCalendar */
@import '~@fullcalendar/common/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';

/* Noty */
@import '~noty/src/noty';
@import '~noty/src/themes/mint';

/* Perfect scrollbar */
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

/* Quill */
@import '~quill/dist/quill.snow.css';

/* Emoji Mart */
@import '~@ctrl/ngx-emoji-mart/picker.css';

/* Lightgallery */
@import '~lightgallery/scss/lightgallery-bundle';

/* CDK */
@import '~@angular/cdk/overlay-prebuilt.css';

$plyr-color-main: #69006d;
@import '~plyr/src/sass/plyr';

/* FontAwesome */
$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import '~@fortawesome/fontawesome-pro/scss/brands';
@import '~@fortawesome/fontawesome-pro/scss/duotone';
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/solid';
@import '~@fortawesome/fontawesome-pro/scss/light';

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

plyr {
    .plyr--loading {
        &:before {
            content: '';
            box-sizing: border-box;
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            width: 50px;
            height: 50px;
            margin-top: -20px;
            margin-left: -20px;
            border-radius: 50%;
            border-top: 2px solid white;
            border-right: 2px solid transparent;
            animation: spinner 0.6s linear infinite;
        }
    }
    &.cover {
        video {
            object-fit: cover;
        }
    }

    &.h-full {
        .plyr {
            height: 100%;

            &__video-wrapper {
                height: 100%;
            }

            video,
            iframe,
            audio {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    &.static {
        .plyr {
            &__video-wrapper {
                background: #000;
                margin: auto;
                overflow: hidden;
                width: 100%;
                padding-bottom: 56.25%;
                position: static;
                height: 0;
            }
        }

        .plyr video,
        .plyr audio,
        .plyr iframe {
            display: block !important;
            height: 100% !important;
            width: 100% !important;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: calc(var(--swiper-navigation-size) / 2.6);
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
}

.swiper-button-next,
.swiper-button-prev {
    position: absolute;
    top: 50%;
    width: var(--swiper-navigation-size);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - var(--swiper-navigation-size) / 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    @apply text-black dark:text-white;
    @apply bg-card;
    @apply rounded-full;
}

.noty_close_button {
    width: 30px;
    height: 30px;

    @apply flex flex-col justify-center items-center mx-1 my-1 text-3xl bg-white font-normal dark:bg-gray-800 dark:text-white  rounded-md  text-gray-400 hover:text-gray-500 focus:outline-none;
}

#credential_picker_container {
    top: 83px !important;
}

.beams {
    background-image: url(/assets/images/etc/carousel-beams.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
}

.beams-mono {
    background-image: url(/assets/images/etc/carousel-beams-light.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
}
.dark {
    .beams-mono {
        background-image: url(/assets/images/etc/carousel-beams-mono-dark.png);
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.dark {
    .beams-reactive {
        background-image: url(/assets/images/etc/carousel-beams.jpg);
        background-size: cover;
        background-repeat: no-repeat;
    }
}
.beams-reactive {
    background-image: url(/assets/images/etc/carousel-beams-light.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
}
