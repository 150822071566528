/* This injects Tailwind's base styles and any base styles registered by plugins. */
@tailwind base;

/* This injects additional styles into Tailwind's base styles layer. */
@layer base {
    * {
        /* Text rendering */
        text-rendering: optimizeLegibility;
        -o-text-rendering: optimizeLegibility;
        -ms-text-rendering: optimizeLegibility;
        -moz-text-rendering: optimizeLegibility;
        -webkit-text-rendering: optimizeLegibility;
        -webkit-tap-highlight-color: transparent;

        /* Remove the focus ring */
        &:focus {
            outline: none !important;
        }

        scrollbar-width: thin;
        scrollbar-color: transparent;

        &::-webkit-scrollbar {
            width: 2px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
            border-radius: 20px;
            border: 3px solid transparent;
        }
    }

    /* HTML and Body default styles */
    html,
    body {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        width: 100%;
        min-height: 100%;
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
        min-height: -webkit-fill-available;
    }

    /* Font size */
    html {
        font-size: 16px;
    }

    body {
        font-size: 0.875rem;
    }

    /* Stylistic alternates for Inter */
    body {
        font-feature-settings: 'salt';
    }

    /* Better spacing and border for horizontal rule */
    hr {
        margin: 32px 0;
        border-bottom-width: 1px;
    }

    /* Make images and videos to take up all the available space */
    img {
        width: 100%;
        vertical-align: top;
    }

    /* Make sure img tags that are broken don't show broken */
    img {
        position: relative;
        overflow: hidden;
        &::before {
            content: attr(data-broken-text);
            width: 100%;
            height: 100%;
            @apply bg-gray-200 dark:bg-gray-700;
            position: absolute;
            top: 0px;
            left: 0px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    /* Fix: Disabled placeholder color is too faded on Safari */
    input[disabled] {
        opacity: 1;
        -webkit-text-fill-color: currentColor;
    }

    body,
    .dark,
    .light {
        @apply text-default bg-default #{'!important'};
    }

    *,
    *::before,
    *::after {
        --tw-border-opacity: 1 !important;
        border-color: rgba(var(--jbz-border-rgb), var(--tw-border-opacity));

        .dark & {
            --tw-border-opacity: 0.12 !important;
        }
    }

    [disabled] * {
        @apply text-disabled #{'!important'};
    }

    /* Print styles */
    @media print {
        /* Make the base font size smaller for print so everything is scaled nicely */
        html {
            font-size: 12px !important;
        }

        body,
        .dark,
        .light {
            background: none !important;
        }
    }
}

/* This injects Tailwind's component classes and any component classes registered by plugins. */
@tailwind components;
@tailwind utilities;

@layer components {
    .text-transform-gradient {
        background: linear-gradient(
            90deg,
            #d1aad7,
            #c88bc4 25.17%,
            #7b8fdd 50%,
            #86bff2 73.09%,
            #bbdef2
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .bg-gradient-x {
        background: linear-gradient(
            90deg,
            #d1aad7,
            #c88bc4 25%,
            #7b8fdd 50%,
            #86bff2 74.87%,
            #bbdef2 99.88%
        );
    }

    .h-fill {
        height: calc(100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0));
        @supports (-webkit-touch-callout: none) {
            height: -webkit-fill-available !important;
        }
    }
    .min-h-fill {
        min-height: calc(100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0));
        @supports (-webkit-touch-callout: none) {
            min-height: -webkit-fill-available !important;
        }
    }
}
