/**
 * Positioning
 */
@function _unpack($shorthand) {
    @if length($shorthand)==1 {
        @return nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1);
    }

    @else if length($shorthand)==2 {
        @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 1) nth($shorthand, 2);
    }

    @else if length($shorthand)==3 {
        @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 3) nth($shorthand, 2);
    }

    @else {
        @return $shorthand;
    }
}

@mixin center($xy:xy) {
    @if $xy==xy {
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        transform: translateX(-50%) translateY(-50%);
    }

    @else if $xy==x {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
    }

    @else if $xy==y {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
    }
}

@function _is-length($value) {
    /* stylelint-disable-next-line */
    @return type-of($value) !='null'and (str-slice($value + '', 1, 4)=='calc'
        or index(auto inherit initial 0, $value) or (type-of($value)=='number'and not(unitless($value))));
}

@mixin position($position: relative, $coordinates: null) {
    @if type-of($position)==list {
        $coordinates: $position;
        $position: relative;
    }

    $coordinates: _unpack($coordinates);

    $offsets: (top: nth($coordinates, 1),
        right: nth($coordinates, 2),
        bottom: nth($coordinates, 3),
        left: nth($coordinates, 4));

    position: $position;

    @each $offset,
    $value in $offsets {
        @if _is-length($value) {
            #{$offset}: $value;
        }
    }
}

@mixin absolute($coordinates) {
    @include position(absolute, $coordinates);
}

@mixin fixed($coordinates) {
    @include position(fixed, $coordinates);
}

@mixin relative($coordinates) {
    @include position(relative, $coordinates);
}

/**
 * Depth
 */
@function z($name) {
    @if index($z-indexes, $name) {
        @return (length($z-indexes) - index($z-indexes, $name))+1;
    }

    @else {
        @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
        @return null;
    }
}

/**
 * Sizing
 */
@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin responsive-ratio($x, $y, $pseudo: false) {
    $padding: unquote(($y / $x) * 100 + '%');

    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding;
        }
    }

    @else {
        padding-top: $padding;
    }
}

/**
 * Margin & Padding
 */
@mixin push($spacing: $space) {
    margin: $spacing;
}

@mixin push--top($spacing: $space) {
    margin-top: $spacing;
}

@mixin push--right($spacing: $space) {
    margin-right: $spacing;
}

@mixin push--bottom($spacing: $space) {
    margin-bottom: $spacing;
}

@mixin push--left($spacing: $space) {
    margin-left: $spacing;
}

@mixin push--ends($spacing: $space) {
    margin: {
        top: $spacing;
        bottom: $spacing;
    }
}

@mixin push--sides($spacing: $space) {
    margin: {
        right: $spacing;
        left: $spacing;
    }
}

@mixin push--auto {
    margin: {
        right: auto;
        left: auto;
    }
}

@mixin offset--sides($spacing: $space) {
    margin: {
        right: -$spacing;
        left: -$spacing;
    }
}

%flush {
    margin: 0;
}

%flush--top {
    margin-top: 0;
}

%flush--right {
    margin-right: 0;
}

%flush--bottom {
    margin-bottom: 0;
}

%flush--left {
    margin-left: 0;
}

%flush--ends {
    margin: {
        top: 0;
        bottom: 0;
    }
}

%flush--sides {
    margin: {
        right: 0;
        left: 0;
    }
}

@mixin soft($spacing: $space) {
    padding: $spacing;
}

@mixin soft--top($spacing: $space) {
    padding-top: $spacing;
}

@mixin soft--right($spacing: $space) {
    padding-right: $spacing;
}

@mixin soft--bottom($spacing: $space) {
    padding-bottom: $spacing;
}

@mixin soft--left($spacing: $space) {
    padding-left: $spacing;
}

@mixin soft--ends($spacing: $space) {
    padding: {
        top: $spacing;
        bottom: $spacing;
    }
}

@mixin soft--sides($spacing: $space) {
    padding: {
        right: $spacing;
        left: $spacing;
    }
}

%hard {
    padding: 0;
}

%hard--top {
    padding-top: 0;
}

%hard--right {
    padding-right: 0;
}

%hard--bottom {
    padding-bottom: 0;
}

%hard--left {
    padding-left: 0;
}

%hard--ends {
    padding: {
        top: 0;
        bottom: 0;
    }
}

%hard--sides {
    padding: {
        right: 0;
        left: 0;
    }
}

/**
 * Typography
 */
@mixin font($font, $color: false, $tt: false, $td: false, $ls: false) {
    @extend %antialiased;

    font: $font;

    @if $color {
        color: $color;
    }

    @if $tt {
        text-transform: $tt;
    }

    @if $td {
        text-decoration: $td;
    }

    @if $ls {
        letter-spacing: $ls;
    }
}

@mixin font-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin font-size($size, $base: 16) {
    font-size: $size; // fallback for old browsers
    font-size: ($size / $base) * 1rem;
}

@mixin truncate($truncation-boundary: false) {
    @if $truncation-boundary {
        max-width: $truncation-boundary;
    }

    @extend %truncated;
}

/**
 * Colors
 */
@function c($color, $value: base) {
    @if map-has-key($ui-colors, $color) {
        $color: map-get($ui-colors, $color);
        $value: map-get($color, $value);

        @return $value;
    }

    @else {
        @warn 'There is no color "#{$color}" in this list; choose one of: #{$ui-colors}';
    }
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;

    @if $orientation=='vertical' {
        background: -webkit-linear-gradient(top, $start-color, $end-color);
        background: linear-gradient(to bottom, $start-color, $end-color);
    }

    @else if $orientation=='horizontal' {
        background: -webkit-linear-gradient(left, $start-color, $end-color);
        background: linear-gradient(to right, $start-color, $end-color);
    }

    @else {
        background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
        background: radial-gradient(ellipse at center, $start-color, $end-color);
    }
}


/**
 * Images
 */
@mixin retina($image, $width, $height) {

    @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
        /* Serving 2x image on Retina display */
        background-image: url($image);
        background-size: $width $height;
    }
}


/**
 * Breakpoints
 */
$layout-breakpoint-xs: 600px !default;
$layout-breakpoint-sm: 960px !default;
$layout-breakpoint-md: 1280px !default;
$layout-breakpoint-lg: 1440px !default;

@mixin breakpoint($class) {

    // width < 600px
    @if $class==xs {
        @media (max-width: $layout-breakpoint-xs) {
            @content;
        }
    }

    // width >= 600px
    @else if $class==gt-xs {
        @media (min-width: $layout-breakpoint-xs) {
            @content;
        }
    }

    // 600px <= width < 960px
    @else if $class==sm {
        @media (min-width: $layout-breakpoint-xs) and (max-width: $layout-breakpoint-sm) {
            @content;
        }
    }

    // width <= 960px
    @else if $class==lt-sm {
        @media (max-width: $layout-breakpoint-sm) {
            @content;
        }
    }

    // width >= 960px
    @else if $class==gt-sm {
        @media (min-width: $layout-breakpoint-sm) {
            @content;
        }
    }

    // 960px <= width < 1280px
    @else if $class==md {
        @media (min-width: $layout-breakpoint-sm) and (max-width: $layout-breakpoint-md) {
            @content;
        }
    }

    // width <= 1280px
    @else if $class==lt-md {
        @media (max-width: $layout-breakpoint-md) {
            @content;
        }
    }

    // width >= 1280px
    @else if $class==gt-md {
        @media (min-width: $layout-breakpoint-md) {
            @content;
        }
    }

    // 1280px <= width < 1920px
    @else if $class==lg {
        @media (min-width: $layout-breakpoint-md) and (max-width: $layout-breakpoint-lg) {
            @content;
        }
    }

    // width <= 1920px
    @else if $class==lt-lg {
        @media (max-width: $layout-breakpoint-lg) {
            @content;
        }
    }

    // width >= 1920px
    @else if $class==gt-lg {
        @media (min-width: $layout-breakpoint-lg) {
            @content;
        }
    }

    // width >= 1920px
    @else if $class==gt-xl {
        @media (min-width: $layout-breakpoint-lg) {
            @content;
        }
    }

    @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg";
    }
}

/**
 * Animation Helpers
 */
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @-moz-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

/**
 * Browser Hacks
 */
@mixin ieOnly {

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
        @content;
    }
}

@mixin firefoxOnly {
    @at-root {
        @-moz-document url-prefix() {
            & {
                @content;
            }
        }
    }
}

@mixin hardware($backface: true, $perspective: 1000) {
    @if $backface {
        backface-visibility: hidden;
    }

    perspective: $perspective;
}

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

/**
 * Misc.
 */
@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}

@mixin input-placeholder {
    &.placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &::-webkit-input-placeholder {
        @content;
    }
}



