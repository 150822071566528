/* ----------------------------------------------------------------------------------------------------- */
/* @ Any configuration we need from Tailwind's config file will be extracted here so we can import this
/* @ file from "config.ts" to access the extracted configuration from TypeScript
/* ----------------------------------------------------------------------------------------------------- */
.jbz-tailwind-extracted-config {
    --breakpoints-print: 'print';
    --breakpoints-xs: '(min-width: 0px)';
    --breakpoints-sm: '(min-width: 600px)';
    --breakpoints-md: '(min-width: 960px)';
    --breakpoints-lg: '(min-width: 1280px)';
    --breakpoints-xl: '(min-width: 1440px)';
    --themes-default: '{"primary":"#4f46e5","accent":"#262626","warn":"#dc2626"}';
    --themes-brand: '{"primary":"#69006d","accent":"#6a74a7","warn":"#dd4858"}';
    --themes-indigo: '{"primary":"#0d9488","accent":"#262626","warn":"#dc2626"}';
    --themes-rose: '{"primary":"#f43f5e","accent":"#262626","warn":"#dc2626"}';
    --themes-purple: '{"primary":"#9333ea","accent":"#262626","warn":"#dc2626"}';
    --themes-amber: '{"primary":"#f59e0b","accent":"#262626","warn":"#dc2626"}';
}
