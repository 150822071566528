.jobzfilepond {
    .filepond--root {
        @apply overflow-hidden border text-black dark:text-white rounded-md shadow-sm bg-white border-gray-300 dark:bg-gray-800 dark:border-gray-500 #{'!important'};
    }

    .filepond--panel-root {
        @apply overflow-hidden border text-black dark:text-white rounded-md shadow-sm bg-white border-gray-300 dark:bg-gray-800 dark:border-gray-500 #{'!important'};
    }

    .filepond--drop-label {
        @apply text-black dark:text-white dark:bg-gray-800;
    }
    .filepond--file {
         @apply dark:text-white dark:bg-gray-600;
    }
}
