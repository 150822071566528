@use '@angular/material' as mat;
@use 'sass:map';

/** Include the core Angular Material styles */
@include mat.core();

/** Configure the Angular Material typography */
@include mat.all-component-typographies(
    mat.define-typography-config(
        $font-family: theme('fontFamily.sans'),
        $title: mat.define-typography-level(1.25rem, 2rem, 600),
        $body-2: mat.define-typography-level(0.875rem, 1.5rem, 600),
        $button: mat.define-typography-level(0.875rem, 0.875rem, 500),
        $input: mat.define-typography-level(0.875rem, 1.2857142857, 400)
            // line-height: 20px
    )
);

/** Prepare the Background and Foreground maps */
$background-light: (
    status-bar: #cbd5e1,
    /* slate.300 */ app-bar: #ffffff,
    background: #f1f5f9,
    /* slate.100 */ hover: rgba(163, 163, 163, 0.12),
    /* neutral.400 + opacity */ card: #ffffff,
    dialog: #ffffff,
    disabled-button: rgba(163, 163, 163, 0.38),
    /* neutral.400 + opacity */ raised-button: #ffffff,
    focused-button: #737373,
    /* neutral.500 */ selected-button: #e2e8f0,
    /* slate.200 */ selected-disabled-button: #e2e8f0,
    /* slate.200 */ disabled-button-toggle: #cbd5e1,
    /* slate.300 */ unselected-chip: #e2e8f0,
    /* slate.200 */ disabled-list-option: #cbd5e1,
    /* slate.300 */ tooltip: #262626 /* neutral.800 */
);

$background-dark: (
    status-bar: #171717,
    /* neutral.900 */ app-bar: #171717,
    /* neutral.900 */ background: #171717,
    /* neutral.900 */ hover: rgba(255, 255, 255, 0.05),
    card: #262626,
    /* neutral.800 */ dialog: #262626,
    /* neutral.800 */ disabled-button: rgba(23, 23, 23, 0.38),
    /* neutral.900 + opacity */ raised-button: #171717,
    /* neutral.900 */ focused-button: #e2e8f0,
    /* slate.200 */ selected-button: rgba(255, 255, 255, 0.05),
    selected-disabled-button: #262626,
    /* neutral.800 */ disabled-button-toggle: #171717,
    /* neutral.900 */ unselected-chip: #525252,
    /* neutral.600 */ disabled-list-option: #e2e8f0,
    /* slate.200 */ tooltip: #737373 /* neutral.500 */
);

$foreground-light: (
    base: #000000,
    divider: #e2e8f0,
    /* slate.200 */ dividers: #e2e8f0,
    /* slate.200 */ disabled: #a3a3a3,
    /* neutral.400 */ disabled-button: #a3a3a3,
    /* neutral.400 */ disabled-text: #a3a3a3,
    /* neutral.400 */ elevation: #000000,
    hint-text: #a3a3a3,
    /* neutral.400 */ secondary-text: #737373,
    /* neutral.500 */ icon: #737373,
    /* neutral.500 */ icons: #737373,
    /* neutral.500 */ mat-icon: #737373,
    /* neutral.500 */ text: #262626,
    /* neutral.800 */ slider-min: #262626,
    /* neutral.800 */ slider-off: #cbd5e1,
    /* slate.300 */ slider-off-active: #a3a3a3 /* neutral.400 */
);

$foreground-dark: (
    base: #ffffff,
    divider: rgba(241, 245, 249, 0.12),
    /* slate.100 + opacity */ dividers: rgba(241, 245, 249, 0.12),
    /* slate.100 + opacity */ disabled: #525252,
    /* neutral.600 */ disabled-button: #262626,
    /* neutral.800 */ disabled-text: #525252,
    /* neutral.600 */ elevation: #000000,
    hint-text: #737373,
    /* neutral.500 */ secondary-text: #a3a3a3,
    /* neutral.400 */ icon: #e2e8f0,
    /* slate.100 */ icons: #e2e8f0,
    /* slate.100 */ mat-icon: #a3a3a3,
    /* neutral.400 */ text: #ffffff,
    slider-min: #ffffff,
    slider-off: #737373,
    /* neutral.500 */ slider-off-active: #a3a3a3 /* neutral.400 */
);

/** Generate Primary, Accent and Warn palettes */
$palettes: ();
@each $name in (primary, accent, warn) {
    $palettes: map.merge(
        $palettes,
        (
            #{$name}:
                (
                    50: var(--jbz-#{$name}-50),
                    100: var(--jbz-#{$name}-100),
                    200: var(--jbz-#{$name}-200),
                    300: var(--jbz-#{$name}-300),
                    400: var(--jbz-#{$name}-400),
                    500: var(--jbz-#{$name}-500),
                    600: var(--jbz-#{$name}-600),
                    700: var(--jbz-#{$name}-700),
                    800: var(--jbz-#{$name}-800),
                    900: var(--jbz-#{$name}-900),
                    contrast: (
                        50: var(--jbz-on-#{$name}-50),
                        100: var(--jbz-on-#{$name}-100),
                        200: var(--jbz-on-#{$name}-200),
                        300: var(--jbz-on-#{$name}-300),
                        400: var(--jbz-on-#{$name}-400),
                        500: var(--jbz-on-#{$name}-500),
                        600: var(--jbz-on-#{$name}-600),
                        700: var(--jbz-on-#{$name}-700),
                        800: var(--jbz-on-#{$name}-800),
                        900: var(--jbz-on-#{$name}-900)
                    ),
                    default: var(--jbz-#{$name}),
                    lighter: var(--jbz-#{$name}-100),
                    darker: var(--jbz-#{$name}-700),
                    text: var(--jbz-#{$name}),
                    default-contrast: var(--jbz-on-#{$name}),
                    lighter-contrast: var(--jbz-on-#{$name}-100),
                    darker-contrast: var(--jbz-on-#{$name}-700)
                )
        )
    );
}

/** Generate Angular Material themes. Since we are using CSS Custom Properties,
    we don't have to generate a separate Angular Material theme for each color
    set. We can just create one light and one dark theme and then switch the
    CSS Custom Properties to dynamically switch the colors. */
body.light,
body .light {
    $base-light-theme: mat.define-light-theme(
        (
            color: (
                $palettes
            )
        )
    );

    $light-theme: (
        color: (
            primary: map.get(map.get($base-light-theme, color), primary),
            accent: map.get(map.get($base-light-theme, color), accent),
            warn: map.get(map.get($base-light-theme, color), warn),
            is-dark: map.get(map.get($base-light-theme, color), is-dark),
            foreground: $foreground-light,
            background: $background-light
        ),
        density: -2
    );

    @include mat.all-component-themes($light-theme);
}

body.dark,
body .dark {
    $base-dark-theme: mat.define-dark-theme(
        (
            color: (
                $palettes
            )
        )
    );

    $dark-theme: (
        color: (
            primary: map.get(map.get($base-dark-theme, color), primary),
            accent: map.get(map.get($base-dark-theme, color), accent),
            warn: map.get(map.get($base-dark-theme, color), warn),
            is-dark: map.get(map.get($base-dark-theme, color), is-dark),
            foreground: $foreground-dark,
            background: $background-dark
        ),
        density: -2
    );

    @include mat.all-component-colors($dark-theme);
}
