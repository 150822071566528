mention-list {
    .mention-menu {
        background-color: var(--jbz-bg-card) !important;
        color: var(--jbz-text-default) !important;
        @apply shadow-lg;
    }
    .mention-item {
        color: var(--jbz-text-default) !important;
    }
    .mention-active {
        > a {
            color: #fff !important;
            background-color: var(--jbz-primary-600) !important;
        }
    }
}
